import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"
import useLiveControl from "../controlled/useLiveControl"
import { DEV_ONLY_PATHS } from "../controlled"

const News = ({ data }) => {
  const { isStaging } = useLiveControl()

  const pressNews = data.wpgraphql.newsmediaplural.edges.find(
    item => item.node.name === "pressnews"
  )?.node?.newsMedia?.edges

  const pressReleases = data.wpgraphql.newsmediaplural.edges.find(
    item => item.node.name === "pressrelease"
  )?.node?.newsMedia?.edges

  const renderedPressNews = isStaging
    ? pressNews
    : pressNews.filter(item => !DEV_ONLY_PATHS.includes(item.node.slug))

  const renderedPressReleases = isStaging
    ? pressReleases
    : pressReleases.filter(item => !DEV_ONLY_PATHS.includes(item.node.slug))

  return (
    <Layout>
      <Seo title={"News"} description={""} />
      <section className="container py-5">
        <div className="row">
          <div className="mb-3 p-3 w-100">
            <h1 className="h2">EnerBank USA in the News</h1>
            <hr />
          </div>
          {renderedPressNews.map((item, i) => {
            return i === 0 ? (
              <div
                className="col-lg-5 py-4 enerbank-news"
                key={item.node.id + i}
              >
                {item?.node?.featuredImage?.node?.sourceUrl && (
                  <img
                    src={item.node.featuredImage.node.sourceUrl}
                    className="img-fluid mb-4"
                    alt={item.node.altText}
                  />
                )}

                <h4
                  className="mb-2"
                  dangerouslySetInnerHTML={{ __html: item.node.title }}
                />
                <h5 className="mb-2 font-weight-normal mention">
                  {item.node.newsmedia.mention} -{" "}
                  {moment(item.node.date).format("MMMM D, Y")}
                </h5>
                <div dangerouslySetInnerHTML={{ __html: item.node.excerpt }} />
                <a
                  href={item.node.newsmedia.newslink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-link p-0"
                >
                  Read more
                </a>
              </div>
            ) : null
          })}

          <div className="col-lg-7 mt-3 mt-lg-0">
            <ul className="media-list">
              {renderedPressNews.map((item, i) => {
                return i !== 0 && i < 4 ? (
                  <li key={item.node.id + i} className="py-4">
                    <h4
                      className="mb-2"
                      dangerouslySetInnerHTML={{ __html: item.node.title }}
                    />
                    <h5 className="mb-2 font-weight-normal mention">
                      {item.node.newsmedia.mention} -{" "}
                      {moment(item.node.date).format("MMMM D, Y")}
                    </h5>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.node.excerpt }}
                      className="mb-auto"
                    />
                    <a
                      href={item.node.newsmedia.newslink.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-link p-0"
                    >
                      Read more
                    </a>
                  </li>
                ) : null
              })}
            </ul>
          </div>
        </div>
        <div className="border-top border-dark pt-4 mt-4 font-weight-normal">
          <Link to={"/pressnews/"}>&lt; Older News Items</Link>
        </div>
      </section>

      <section className="bg-grey py-5">
        <div className="container">
          <h1 className="h2">EnerBank Press Releases</h1>
          <hr className="mb-4" />
          <div className="row">
            {renderedPressReleases.map((item, i) => {
              return i === 0 ? (
                <div
                  className="col-lg-5 py-4 enerbank-news"
                  key={item.node.id + i}
                >
                  {item?.node?.featuredImage?.node?.sourceUrl && (
                    <img
                      src={item.node.featuredImage.node.sourceUrl}
                      className="img-fluid mb-4"
                      alt={item.node.altText}
                    />
                  )}
                  <h4
                    className="mb-2"
                    dangerouslySetInnerHTML={{ __html: item.node.title }}
                  />
                  <h5 className="mb-2 font-weight-normal mention">
                    {item.node.newsmedia.mention} -{" "}
                    {moment(item.node.date).format("MMMM D, Y")}
                  </h5>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.node.excerpt }}
                    className="mb-auto"
                  />
                  <a
                    href={"/pressrelease/" + item.node.slug + "/"}
                    className="btn-link p-0"
                  >
                    Read more
                  </a>
                </div>
              ) : null
            })}

            <div className="col-lg-7 mt-5 mt-lg-0">
              <ul className="media-list">
                {renderedPressReleases.map((item, i) => {
                  return i !== 0 && i < 4 ? (
                    <li className="py-4" key={item.node.id + i}>
                      <h4
                        className="mb-2"
                        dangerouslySetInnerHTML={{ __html: item.node.title }}
                      />
                      <h5 className="mb-2 font-weight-normal mention">
                        {item.node.newsmedia.mention} -{" "}
                        {moment(item.node.date).format("MMMM D, Y")}
                      </h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.node.excerpt,
                        }}
                        className="mb-auto"
                      />
                      <a
                        href={"/pressrelease/" + item.node.slug + "/"}
                        className="btn-link p-0"
                      >
                        Read more
                      </a>
                    </li>
                  ) : null
                })}
              </ul>
            </div>
          </div>
          <div className="border-top border-dark pt-4 mt-4 font-weight-normal">
            <Link to={"/pressreleases/"}>&lt; Older Press Releases</Link>
          </div>
        </div>
      </section>

      <section className="container py-5">
        {/* <div className="row">
          <h2 className="h2 mb-5 col-12">EnerBank on Social Media</h2>
        </div> */}
        {/* <div className="row justify-content-between px-3 social-card">
          {data.allTwitterStatusesUserTimelineHashtagGatsby.edges.map(
            (tweet, i) =>
              tweet &&
              i < 4 && (
                <TwitterTweetEmbed
                  tweetId={tweet.node.id_str}
                  key={tweet.node.id + i}
                />
              )
          )}
        </div> */}
        <div className="row">
          <div className="col-12">
            <h2 className="text-center mb-3 mediaheader">
              Please Follow and Subscribe to EnerBank
            </h2>
            <div className="mediaIcon mx-auto socialicon">
              <a
                href="https://www.facebook.com/enerbank"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue"
                title="EnerBank Facebook"
              >
                <FontAwesomeIcon
                  alt="EnerBank Facebook"
                  title="EnerBank Facebook"
                  icon={faFacebookF}
                  color="#ffffff"
                />
              </a>
              <a
                href="https://twitter.com/enerbank"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue"
                title="EnerBank Twitter"
              >
                <FontAwesomeIcon
                  alt="EnerBank Twitter"
                  title="EnerBank Twitter"
                  icon={faTwitter}
                  color="#ffffff"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/enerbank-usa?trk=tyah&trkInfo=tarId%3A1395948482719%2Ctas%3Aenerbank%2Cidx%3A1-1-1"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue"
                title="EnerBank LinkedIn"
              >
                <FontAwesomeIcon
                  alt="EnerBank LinkedIn"
                  icon={faLinkedinIn}
                  title="EnerBank LinkedIn"
                  color="#ffffff"
                />
              </a>
            </div>
            <h2 className="text-center mt-3 mb-2 mediaheader">
              Press/Media Contact
            </h2>
            <p className="text-center mediacontact">
              SnappConner PR
              <br />
              <span>
                801.806.0150
                <br />
                info@snapconner.com
              </span>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default News
export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }
      }

      newsmediaplural {
        edges {
          node {
            id
            name
            newsMedia(where: { orderby: { order: DESC, field: DATE } }) {
              edges {
                node {
                  id
                  title
                  date
                  excerpt
                  slug
                  newsmedia {
                    fieldGroupName
                    newslink {
                      target
                      title
                      url
                    }
                    mention
                  }
                  featuredImage {
                    node {
                      sourceUrl
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
